import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Menu } from "antd"
import sertissage from "./sertissage"

const bijoux = () => (
  <Layout>
    <SEO title="Bijoux" />

    <div className="slideshow">
      <div className="usp">
        <div  className="usp-txt-header">Notre savoir-faire</div>
      </div>
    </div>
    <div className="border-gold-bottom"></div>

    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Notre savoir-faire</h2>


    <section className="section-flex-04">

      <div className="section-flex-03">

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/creation" className="gold-box-small-text">Création sur mesure</Link>
          </div>
        </div>

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/transformation" className="gold-box-small-text">Transformation</Link>
          </div>
        </div>

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/expertises" className="gold-box-small-text">Estimations et Expertises</Link>
          </div>
        </div>

      </div>

      <div className="section-flex-03">

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/restauration" className="gold-box-small-text">Restauration</Link>
          </div>
        </div>

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/sertissage" className="gold-box-small-text">Sertissage</Link>
          </div>
        </div>

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/gravures" className="gold-box-small-text">Gravures</Link>
          </div>
        </div>

      </div>

      <div className="section-flex-03">

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/renfilage" className="gold-box-small-text">Renfilage de Colliers</Link>
          </div>
        </div>

        <div className="gold-box-small">
          <div className="gold-box-small-img-Bagues">
            <Link to="/achat_vieil_or" className="gold-box-small-text">Achat vieil Or</Link>
          </div>
        </div>

      </div>

    </section>

  </Layout>
)

export default bijoux
